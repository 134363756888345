import React, { useState, useEffect } from 'react';
import { IoMdMenu, IoMdClose, IoMdCall } from 'react-icons/io';
import { Link, withRouter } from 'react-router-dom';

import { Img } from 'component/FrameL';
import './style.css';

function DesktopNavbar({ Logo, CoreData = {} }) {
  const [isOpen, setIsOpen] = useState(true);
  const { bookingLink } = CoreData;

  useEffect(() => {
    const handleScrollClose = () => {
      setIsOpen(false);
    }

    document.addEventListener('scroll', handleScrollClose);

    return () => {
      document.removeEventListener('scroll', handleScrollClose);
    }
  }, [])

  return (<div className="fixed z-20">
    <div className={`fixed top-0 left-0 m-4 p-2 navbar-bg-color ${isOpen ? 'hidden' : 'block'}`} onMouseMove={() => {
      setIsOpen(true);
    }}>
      <IoMdMenu className="text-4xl "></IoMdMenu>
    </div>
    <div className={`p-8 navbar__bg-container ${isOpen ? 'visible' : ''}`}>
      <div className="xl:container navbar__content mx-auto flex justify-around items-center text-xl">
        <Link to="/rooms">
          Rooms
        </Link>
        <Link to="/specials">
          Specials
        </Link>
        <Link to="/downtown">
          Downtown
        </Link>
        <Link to="/dining">
          Dining
        </Link>
        <Link to="/">
          <Img className="navbar__logo--width" Image={Logo} />
        </Link>
        <Link to="/media">
          Media
        </Link>
        <Link to="/thingstodo">
          Things To Do
        </Link>
        <Link to="/about">
          About
        </Link>
        <Link to="/meetings">
          Meetings
        </Link>
      </div>
    </div>
    <div className={`fixed mt-4 mr-4 top-0 right-0 flex flex-row z-10`}>
      <a
        href={bookingLink}
        className="p-2 px-4 navbar-bg-color text-2xl mr-2 no-underline">
        Book Now
      </a>
    </div>
  </div>
  );
}

function MobileNavbar({ Logo, CoreData = {} }) {
  const [isOpen, setIsOpen] = useState(false);
  const { bookingLink } = CoreData;

  return (<div className="fixed z-20">
    <div className={`fixed mt-4 ml-4 top-0 left-0 p-2 navbar-bg-color`} onClick={() => {
      setIsOpen(true);
    }}>
      <IoMdMenu className="text-4xl text-white"></IoMdMenu>
    </div>
    <div className={`fixed mt-4 mr-4 top-0 right-0 p-2 navbar-bg-color`}>
      <a
        href={bookingLink}>
        <IoMdCall className="text-4xl text-white" />
      </a>
    </div>
    <div className={`text-white text-accent text-2xl uppercase navbar-bg-color flex-col flex items-center p-8 py-16 mobile-nav__container ${isOpen ? 'visible' : ''}`}>
      <IoMdClose className="absolute top-0 right-0 text-4xl m-8" onClick={() => {
        setIsOpen(false);
      }} />
      <Link to="/" onClick={() => {
        setIsOpen(false);
      }}>
        <Img className="navbar__logo--width" Image={Logo} />
      </Link>
      <Link to="/rooms" onClick={() => {
        setIsOpen(false);
      }}>
        Rooms
      </Link>
      <Link to="/specials" onClick={() => {
        setIsOpen(false);
      }}>
        Specials
      </Link>
      <Link to="/downtown" onClick={() => {
        setIsOpen(false);
      }}>
        Downtown
      </Link>
      <Link to="/dining" onClick={() => {
        setIsOpen(false);
      }}>
        Dining
      </Link>
      <Link to="/media" onClick={() => {
        setIsOpen(false);
      }}>
        Media
      </Link>
      <Link to="/thingstodo" onClick={() => {
        setIsOpen(false);
      }}>
        Things To Do
      </Link>
      <Link to="/about" onClick={() => {
        setIsOpen(false);
      }}>
        About
      </Link>
      <Link to="/meetings" onClick={() => {
        setIsOpen(false);
      }}>
        Meetings
      </Link>
    </div>
  </div>
  );
}

const NavBar = ({ cmsData }) => {
  const { Navigation, CoreData } = cmsData;
  const { Logo, LogoWhite } = Navigation;

  return (
    <React.Fragment>
      <div className="hide-on-lg">
        <DesktopNavbar Logo={Logo} CoreData={CoreData} />
      </div>
      <div className="block lg:hidden">
        <MobileNavbar Logo={Logo} CoreData={CoreData} />
      </div>
    </React.Fragment>
  );
}

export default withRouter(NavBar);