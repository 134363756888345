/**
 * @module cluster-l
 * @description
 * A custom element for grouping items, with control over the margin between them
 * @property {string} justify=center A CSS `justify-content` value
 * @property {string} align=center A CSS `align-items` value
 * @property {string} space=var(--s1) A CSS `margin` value. The minimum space between the clustered child elements.
 */
import './Cluster.css';

export default class Cluster extends HTMLElement {
    constructor() {
        super();
        this.render = () => {
            this.i = `Cluster-${[this.justify, this.align, this.space].join('')}`;
            this.dataset.i = this.i;
            if (!document.getElementById(this.i)) {
                let styleEl = document.createElement('style');
                styleEl.id = this.i;
                styleEl.innerHTML = `
            [data-i="${this.i}"] > * {
              justify-content: ${this.justify};
              align-items: ${this.align};
              margin: calc(${this.space} / 2 * -1);
            }
        
            [data-i="${this.i}"] > * > * {
              margin: calc(${this.space} / 2);
            }
          `.replace(/\s\s+/g, ' ').trim();
                document.head.appendChild(styleEl);
            }
        }
    }

    get justify() {
        return this.getAttribute('justify') || 'center';
    }

    set justify(val) {
        return this.setAttribute('justify', val);
    }

    get align() {
        return this.getAttribute('align') || 'center';
    }

    set align(val) {
        return this.setAttribute('align', val);
    }

    get space() {
        return this.getAttribute('space') || 'var(--s0)';
    }

    set space(val) {
        return this.setAttribute('space', val);
    }

    static get observedAttributes() {
        return ['justify', 'align', 'space'];
    }

    connectedCallback() {
        this.render();
    }

    attributeChangedCallback() {
        this.render();
    }
}

if ('customElements' in window) {
    customElements.define('cluster-l', Cluster);
}