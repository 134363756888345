import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import Navbar from './container/NavBar';
import FavouriteManager from './container/FavouriteManager';
import Footer from './component/Footer';
import Loader from './component/Loader';
import { PopupControl } from 'component/PopupControl/PopupControl';

const HomePage = React.lazy(() => import('./container/Home'));
const AboutPage = React.lazy(() => import('./container/About'));
const ThingsToDoPage = React.lazy(() => import('./container/ThingsToDo'));
const SpecialsPage = React.lazy(() => import('./container/Specials'));
const EventsPage = React.lazy(() => import('./container/Events'));
const RoomsPage = React.lazy(() => import('./container/Rooms'));
const DowntownPage = React.lazy(() => import('./container/Downtown'));
const FortyEightHoursPage = React.lazy(() => import('./container/48Hours'));
const DiningPage = React.lazy(() => import('./container/Dining'));
const PressPage = React.lazy(() => import('./container/Press'));
const GroupContactForm = React.lazy(() => import('./component/ContactForm'));
const MeetingsPage = React.lazy(() => import('./container/Meetings'));

function App() {
  const [data, setPageData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/all`)
      .then(response => response.json())
      .then(data => {
        setPageData(data);
      }).catch(error => {
        // @todo handle error
        console.log('Something went wrong');
      })
  }, []);

  if (!data) {
    return <Loader></Loader>
  }

  const { Rooms, Home, ThingsToDo, About, Press, Specials, FortyEightHours, Policies, CoreData, Navigation, PointsOfInterest, Dining, AmpRooms, AmpAddOns, AmpLMS, AmpPackages, AmpEvents, AmpFortyEightHours, MovieMaker, Popup, Meetings, AmpMeetings } = data;
  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        {Popup && !Popup.Disabled && <PopupControl key={location.pathname} cmsData={Popup}></PopupControl>}
        <Navbar cmsData={{ Navigation, CoreData }} />
        <Switch>
          <Route exact path="/rooms" component={() => <RoomsPage cmsData={Rooms} Policies={Policies} AmpRooms={AmpRooms} />} />
          <Route exact path="/thingstodo" component={() => <ThingsToDoPage cmsData={ThingsToDo} />} />
          <Route exact path="/downtown" component={() => <DowntownPage cmsData={PointsOfInterest} />} />
          <Route exact path="/media" component={() => <PressPage cmsData={Press} />} />
          <Route exact path="/about" component={() => <AboutPage cmsData={About} />} />
          <Route exact path="/dining" component={() => <DiningPage cmsData={Dining} />} />
          <Route exact path="/specials" component={() => <SpecialsPage cmsData={Specials} AmpAddOns={AmpAddOns} AmpLMS={AmpLMS} AmpPackages={AmpPackages} />} />
          <Route exact path="/events" component={() => <EventsPage cmsData={Specials} AmpEvents={AmpEvents} />} />
          <Route exact path="/48hours" component={() => <FortyEightHoursPage cmsData={FortyEightHours} AmpFortyEightHours={AmpFortyEightHours} />} />
          <Route exact path="/contact" component={() => <GroupContactForm />} />
          {/* <Route exact path="/dining" component={() => <MovieMakerPage cmsData={MovieMaker} />} /> */}
          <Route exact path="/meetings" component={() => <MeetingsPage cmsData={Meetings} AmpMeetings={AmpMeetings} />} />
          <Route exact path="/" component={() => <HomePage cmsData={Home} roomsCmsData={Rooms} coreData={CoreData} />} />
          <Route exact path="/our-history-grounds" render={() => { return <Redirect to="/About"></Redirect> }}></Route>
          <Route exact path="/bed-and-breakfast-hosts" render={() => { return <Redirect to="/About"></Redirect> }}></Route>
          <Route exact path="/breakfast" render={() => { return <Redirect to="/dining"></Redirect> }}></Route>
          <Route exact path="/our-history-grounds/#news" render={() => { return <Redirect to="/"></Redirect> }}></Route>
          <Route exact path="/gift-certificates" render={() => { return <Redirect to="/"></Redirect> }}></Route>
          <Route exact path="/policies/#terms" render={() => { return <Redirect to="/rooms#roomPolicies"></Redirect> }}></Route>
          <Route exact path="/bed-and-breakfast-guest-rooms" render={() => { return <Redirect to="/rooms"></Redirect> }}></Route>
          <Route exact path="/meeting-space-at-the-carriage-house" render={() => { return <Redirect to="/meetings"></Redirect> }}></Route>
          <Route exact path="/bed-and-breakfast-guest-rooms" render={() => { return <Redirect to="/rooms"></Redirect> }}></Route>
          <Route exact path="/policies/#reservation" render={() => { return <Redirect to="/rooms#roomPolicies"></Redirect> }}></Route>
          <Route exact path="/specials-packages" render={() => { return <Redirect to="/specials"></Redirect> }}></Route>
          <Route exact path="/gettysburg-things-to-do" render={() => { return <Redirect to="//thingstodo"></Redirect> }}></Route>
          <Route exact path="/blog" render={() => { return <Redirect to="/media"></Redirect> }}></Route>

          <Route path="*" render={() => { return <Redirect to="/" ></Redirect> }}></Route>
        </Switch>
        <Footer cmsData={{ Policies, CoreData }} />
        {/* <FavouriteManager CoreData={CoreData} Rooms={AmpRooms} LMS={AmpLMS} FortyEightHours={AmpFortyEightHours} Packages={AmpPackages} /> */}
      </div>
    </Suspense>
  );
}

export default App;
