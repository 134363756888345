import React from 'react';

export default () => {
    return <div className="lds-roller-container">
        <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <h3>Loading</h3>
    </div>
}

export const LoaderComponent = () => {
    return <>
        <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <h3>Loading</h3>
    </>
}